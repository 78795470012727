@use 'styles/base/mixins';
@use 'styles/base/variables';
@use '../../checkout.module.scss';

.h1 {
  @include mixins.word-wrap();
  @include mixins.set-responsive-font-sizes(31, 35, 42);
  margin-bottom: variables.$spacing-m;

  @include mixins.below-screen-sm {
    margin-top: variables.$spacing-m;
    line-height: 2.5rem;
  }

  > span {
    display: block;
    font-size: inherit;
    font-weight: variables.$font-weight-bold;
  }
}

.h3 {
  @include mixins.word-wrap();
  @include mixins.set-responsive-font-sizes(18, 20, 24);
  font-weight: variables.$font-weight-bold;
}

.customerNumberText {
  display: block;
  font-size: inherit;
  font-weight: variables.$font-weight-bold;
  text-transform: uppercase;
}

.bottomMargin {
  margin-bottom: variables.$spacing-m;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.backButton {
  padding: 0 !important;
  border: none !important;

  @include mixins.below-screen-sm {
    display: none;
  }
}

.editButton:hover {
  color: #000;
  background-color: variables.$gb_black_08;
}

.cartFooter {
  min-height: 74px !important;
  background: variables.$gb_grey_050;

  @include mixins.below-screen-sm {
    padding-top: variables.$spacing-xs;
    padding-bottom: variables.$spacing-xs;
  }

  &Text {
    @include mixins.below-screen-sm {
      margin-bottom: variables.$spacing-s;
    }
  }
}

.loaderWrapper {
  display: block;
  width: 100%;
  margin: 30% 0;

  > div {
    margin: auto;
  }
}

.basketLine {
  margin-top: 6.25rem;
  margin-bottom: variables.$spacing-m;
}

.formHeading {
  margin-bottom: 0;

  @include mixins.below-screen-sm {
    margin-bottom: 0.75rem;
  }
}

.formExplanationText {
  text-align: right;
  color: variables.$gb_grey_600;

  @include mixins.below-screen-sm {
    text-align: left;
  }
}
