@use 'styles/base/variables';
@use 'styles/base/mixins';
@use 'styles/base/fonts';

.landingPage {
  h2 {
    margin-bottom: 4.375rem;

    @include mixins.screen-md {
      margin-bottom: 3rem;
    }
  }

  .banner {
    background-image: url('/images/campus-login.jpg');
    background-size: cover;
    background-position: center;

    @include mixins.below-screen-sm {
      background-image: url('/images/campus-login-mobile.jpg');
      background-size: auto;
      height: 360px;
    }
  }

  .content {
    margin: 4.5rem auto;

    p {
      max-width: none;
    }
  }

  .buttonContainer {
    display: flex;

    a.loginButton {
      -webkit-appearance: none;
      width: auto;
      color: variables.$gb_white;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }

      &::before {
        content: '';
        display: none;
      }
    }
  }

  .pictos {
    margin-bottom: 3.75rem;

    img {
      width: 3.75rem;
      height: auto;
      align-self: flex-start;
    }

    :global(.cell) {
      display: flex;
      margin-bottom: 32px;

      @include mixins.screen-md {
        margin-bottom: 0;
      }

      > :first-child {
        margin-right: 1.25rem;
      }

      .benefitContent {
        b {
          margin-bottom: 0.5rem;
        }
      }
    }
    > :last-child {
      margin-bottom: 0;
    }
  }

  hr {
    margin: 5.5rem 0 3.75rem 0;
  }

  button[type='button'] {
    -webkit-appearance: none;
  }
}
