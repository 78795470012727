@use 'styles/base/mixins';
@use 'styles/base/variables';

.courseDescriptionWrapper {
  flex-direction: column;

  &.courseDescriptionWrapperNoPad {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    max-width: 100%;
  }

  ul {
    font-size: 1rem;
    margin-bottom: 3rem;
    margin-top: 1.25rem;

    li u,
    li del {
      text-decoration: none;
    }
  }

  h1,
  h2 {
    font-size: 1.625rem;
    margin-bottom: 1.25rem;

    @include mixins.screen-sm {
      font-size: 1.75rem;
    }

    @include mixins.screen-md {
      font-size: 2rem;
    }
  }

  h3 {
    margin-bottom: 1.25rem;
    text-transform: unset;
  }
}

.tutorWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 3.75rem;

  @include mixins.screen-sm {
    flex-direction: row;
  }
}

.tutors {
  background-color: variables.$gb_grey_050;
  display: flex;
  flex-direction: row;
  height: auto;
  padding: 1rem 1.5rem 1rem 1.5rem;
  margin-bottom: 1.25rem;
  min-height: 6rem;
  position: relative;

  @include mixins.screen-sm {
    width: 48%;
  }

  &:after {
    background-color: variables.$gb_white;
    bottom: -0.625rem;
    content: '';
    height: 1.5rem;
    position: absolute;
    right: -0.813rem;
    transform: rotate(40deg);
    width: 1.5rem;
  }
  &:nth-of-type(odd) {
    @include mixins.screen-sm {
      margin-right: 1.5rem;
    }
  }
}

.tutorsInner {
  display: flex;
  flex-direction: column;
}

.tutorsImage {
  background-color: variables.$gb_white;
  border-radius: 50%;
  height: 4rem;
  margin-right: 1rem;
  width: 4rem;

  img {
    border-radius: 50%;
    height: 4rem;
    margin-right: 1rem;
    width: 4rem;
    object-fit: cover;
  }
}

.tutorsRole {
  color: variables.$gb_grey_700;
  font-size: 0.813rem;
}

.tutorsName {
  font-size: 1rem;
  font-weight: bold;
}

.tutorsPosition {
  font-size: 0.875rem;
}
