@use 'styles/base/mixins';
@use 'styles/base/variables';

// GDDS Headlines
.gddsHeadlineH2 {
  margin-bottom: 2.5rem;

  word-wrap: break-word;

    @include mixins.screen-lg {
    margin-bottom: 3rem;
  }
}

.hotelLabel {
  font-size: 0.75rem;
  line-height: 1rem;
  color: variables.$gb_grey_650;
  padding-bottom: 0.25rem;
}

.buttonsSubmit {
  justify-content: right;
  display: flex;
  flex-direction: column;

  @include mixins.screen-sm {
    flex-direction: row;
  }

  & > button {
    @include mixins.screen-sm {
      margin-left: 1rem;
    }

    &:first-of-type {
      margin-top: 1rem;
      order: 1;

      @include mixins.screen-sm {
        margin-top: 0;
        order: 0;
      }
    }
  }

  .submitHint {
    flex-grow: 1;
    font-size: 0.75rem;
    margin-bottom: 2rem;

    @include mixins.screen-md {
      margin-bottom: 0;
    }
  }
}

.radioToggle {
  margin-top: 0.625rem;

  @include mixins.screen-lg {
    margin-bottom: 1.5rem;
  }
}

.checkboxToggle {
  @include mixins.screen-lg {
    margin-bottom: 1.5rem;
  }

  &#{&}#{&} a {
    align-items: center;

    span:first-child {
      padding-right: 0;
    }
  }
}

form .errorBorder input {
  border: 1px solid variables.$gb_gdds_error;
}

.registrationForm {
  padding-bottom: 6.5625rem;
  border-bottom: none;

  @include mixins.screen-lg {
    padding-bottom: 7.5rem;
  }

  p {
    word-break: inherit;
  }

  strong {
    font-size: 1rem;
    font-weight: variables.$font_weight_bold;
    line-height: 1.5;
  }

  hr {
    margin-bottom: 2.5rem;
    margin-top: 1rem;

    @include mixins.screen-lg {
      margin-bottom: 3rem;
    }
  }

  .commentWrapper {
    margin-bottom: 3rem;

    strong {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

  :global(.form-item),
  :global(.form-items) {
    margin-bottom: 1.5rem;
  }

  :global(.left) {
    @include mixins.screen-sm {
      padding-right: 0.75rem;
    }
  }

  :global(.right) {
    @include mixins.screen-sm {
      padding-left: 0.75rem;
    }
  }

  :global(.grid-x > div) {
    margin-bottom: 1rem;
  }

  :global(input[type='text']) {
    padding-left: 0.5rem;
  }

  :global(button .c-tpp-area),
  :global(.delete-button .c-tpp-area) {
    width: auto;
    height: auto;
  }

  :global(.delete-button) {
    color: variables.$gb_gdds_cl17;
    position: relative;
    top: -0.625rem;
    -webkit-appearance: none;

    span svg {
      fill: variables.$gb_gdds_cl17;
    }
  }

  :global(.headline-button-wrapper) {
    justify-content: space-between;
  }

  :global(.form-section) {
    margin-bottom: 1.5rem;
  }
}
