@use '../../checkout.module.scss';

.linkWrapper {
  > a {
    font-size: inherit;
    padding: 0;
    display: inline;
  }

  > a::before {
    content: none;
  }
}
