@use 'styles/base/mixins';
@use 'styles/base/variables';
@use '../CourseTeaser/courseTeaser.module.scss';
@use './confirmationButton.module.scss';

.headline h3 {
  margin-bottom: 2rem;
}

.cancelCourse {
  .backToCourse {
    margin: 1rem 0 2.5rem 0;

    a::before {
      content: '\f10a';
    }
  }
}

.courseHeadlines {
  @include mixins.screen-md {
    padding-right: 5rem;
    width: 53rem;
  }

  h4 {
    margin-bottom: 1.75rem;
    @include mixins.line-clamp(3);

    @include mixins.screen-sm {
      @include mixins.line-clamp(2);
    }

    @include mixins.screen-md {
      @include mixins.line-clamp(1);
    }
  }
}

.courseTeaserWrapper {
  margin-top: 4rem;
}

.courseTeaser {
  @include mixins.screen-sm {
    margin-left: 1.563rem;
    padding: 1.75rem 4.5rem 0 4.5rem;
  }

  .courseInnerWrapper {
    @include mixins.screen-sm {
      padding-bottom: 2rem;
    }

    @include mixins.screen-md {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0;
    }

    h4 {
      text-transform: unset;
    }
  }

  .courseInformation {
    position: relative;
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }

  .item {
    margin: 0;
    width: inherit;

    b {
      margin-left: 0.7rem;
    }
  }
}

.itemContent {
  margin-left: 0.7rem;
  display: flex;

  span:first-of-type {
    display: flex;
    margin-right: 1rem;
  }
}

.participants {
  @include mixins.screen-sm {
    margin-top: 3.4rem;
  }
}

.participantsList {
  border-bottom: 1px solid variables.$gb_grey_150;
  padding-bottom: 2rem;

  @include mixins.screen-sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.participantsListItem > div {
  flex-wrap: wrap;

  // overwrite actual gdds styles because of line-clamp
  label {
    @include mixins.line-clamp(1);
    line-height: 1.25rem;
  }

  > div {
    margin-right: 0;

    label {
      color: variables.$gb_grey_800;
      font-size: 14px;
    }

    @include mixins.screen-sm {
      width: 13.8rem;
    }

    @include mixins.screen-md {
      width: 17.25rem;
    }
  }
}

.cancellationForm {
  border-bottom: none;
}
