@use 'styles/base/mixins';
@use 'styles/base/variables';
@use './confirmationLayer.module.scss';

.formButtons,
.modalButtons {
  margin-top: 2rem;

  @include mixins.screen-sm {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  button {
    width: 100%;
    @include mixins.screen-sm {
      width: inherit;
    }
  }

  button:first-of-type {
    margin-bottom: 1rem;

    @include mixins.screen-sm {
      order: 1;
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  button:last-of-type {
    margin-right: 2rem;
  }
}

.modalButtons {
  margin-top: 0;
}

.buttonWrapper {
  background-color: variables.$gb_white;
  border-top: variables.$gb_grey_150 1px solid;
  padding: 1.5rem 0;
  bottom: 0;
  position: fixed;
  width: 87vw;

  @include mixins.screen-sm {
    bottom: 0;
    height: 5.563rem;
    margin-right: 1.688rem;
    position: absolute;
    width: 33.5rem;
  }
}

.scrollWrapper {
  height: calc(100vh - 20rem);
  overflow: hidden;
  overflow-y: auto;

  @include mixins.screen-sm {
    height: 13.55rem;
    min-height: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
}
