@use 'styles/base/mixins';
@use 'styles/base/variables';

.headlineMyCourse {
  margin-bottom: 3rem;

  @include mixins.screen-sm {
    margin-bottom: 2.5rem;
  }
}

.headlineStatus {
  margin-bottom: 1.625rem;
}

.tabWrapper {
  margin-bottom: 3rem;

  button {
    border-bottom: 4px solid transparent;
    margin-right: 1.5rem;
    padding-bottom: 0.625rem;
    color: variables.$gb_black;
    font-weight: variables.$font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.5rem;

    &.tabActive {
      border-bottom: 3px solid var(--gb-brand-primary-dark);
    }
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
}

.wrapper {
  margin-bottom: 3rem;
}

.moduleList {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.module,
.module:last-of-type {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 2rem 1.5rem 2.5rem 1.5rem;

  :global(.progress-bar) {
    margin-bottom: 1.25rem;
  }

  :global(.actions button) {
    width: 100%;
  }

  @include mixins.screen-sm {
    padding: 1.25rem 1.875rem 1.25rem 2rem;
    flex-direction: row;

    :global(.progress-bar) {
      margin-bottom: 0;
    }

    :global(.actions button) {
      width: auto;
    }
  }

  @include mixins.screen-md {
    padding: 1.25rem 1.875rem 1.25rem 3rem;
  }

  &:last-of-type::after {
    display: none;
  }
}

.module {
  background: variables.$gb_grey_050;
}

.moduleFinished {
  position: relative;

  &::after {
    content: '';
    width: 1px;
    height: 1.5rem;
    background: variables.$gb_black;
    position: absolute;
    transform: translateY(100%);
    left: 3.5rem;
    bottom: 0;

    @include mixins.screen-sm {
      left: 5.25rem;
    }

    @include mixins.screen-md {
      left: 7.25rem;
    }
  }
}

.moduleLocked {
  background: transparent;
  border: 1px solid variables.$gb_grey_150;
  color: variables.$gb_grey_150;
}

.textBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;

  strong {
    margin-bottom: 0.5rem;
    font-weight: variables.$font-weight-bold;
    color: variables.$gb_black;
    font-size: 1.25rem;
    line-height: 1.75rem;

    @include mixins.line-clamp(2);

    @include mixins.screen-sm {
      @include mixins.line-clamp(1);
    }
  }

  p {
    font-weight: variables.$font-weight-regular;
    color: variables.$gb_black;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;

    @include mixins.line-clamp(3);

    @include mixins.screen-sm {
      margin-bottom: 0;
      @include mixins.line-clamp(2);
    }
  }
}
