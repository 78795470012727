@use 'styles/base/mixins';
@use 'styles/base/variables';

.courseTeaser {
  margin-bottom: 4rem;

  @include mixins.screen-md {
    display: flex;
    flex-direction: row;
  }

  @include mixins.screen-lg {
    margin-bottom: 5rem;
  }

  .imageContainer {
    margin-bottom: 2.5rem;

    @include mixins.screen-md {
      padding-right: 2rem;
      width: 50%;
      margin-bottom: 0;
    }

    img {
      width: 100%;
    }
  }

  .courseDetails {
    @include mixins.below-screen-xs {
      display: flex;
      flex-direction: column;
    }

    @include mixins.screen-md {
      width: 50%;
      padding-left: 2rem;
    }

    .flags {
      margin-bottom: 0.5rem;

      .status {
        display: inline-block;
        padding: 0.125rem 0.625rem;
        font-size: 0.75rem;
        margin: 0 0.5rem 0.5rem 0;
        color: variables.$gb_white;

        :global(.available) {
          background-color: variables.$gb_capacitycolor_green;
        }

        :global(.canceled) {
          background-color: variables.$gb_textcolor_expiration_date;
        }

        &:global(.booked) {
          background-color: var(--gb-brand-primary-dark);
        }
      }
    }

    .info {
      background: variables.$gb_grey_050;
      padding: 0.75rem 1rem;

      > span {
        float: left;
        margin: 0.2rem 0.5rem 0 0;
      }

      > div {
        margin-left: 1.5rem;
        > p {
          margin-bottom: 0;
          &.clamp {
            @include mixins.line-clamp(2);
          }
        }
      }
    }
    .buttonWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;

      @include mixins.screen-sm {
        flex-direction: row;
      }

      > button:first-of-type {
        margin-bottom: 1rem;

        @include mixins.screen-sm {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
