@use 'styles/base/variables';

.campusVideo {
  display: flex;
}

.videoBg {
  background: variables.$gb_grey_055;
  display: flex;
  flex: 1;

  iframe {
    flex: 1;
  }
}
