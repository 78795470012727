@use 'styles/base/mixins';
@use '../../checkout.module.scss';
@use 'styles/base/variables';

.h1 {
  @include mixins.word-wrap();
  @include mixins.set-responsive-font-sizes(31, 35, 42);
  margin-bottom: variables.$spacing-xl;
  margin-top: variables.$spacing-xl;

  @include mixins.below-screen-sm {
    margin-top: variables.$spacing-m;
    line-height: 2.5rem;
  }
}

.explanationText {
  max-width: variables.$gb_containerMaxWidth;
}

.bottomMargin {
  margin-bottom: variables.$spacing-xxxl !important;
}
