@use '../../checkout.module.scss';
@use 'styles/base/variables';

.bottomMargin {
  margin-bottom: variables.$spacing-m;
}

.errorContainer {
  width: 100%;
  padding: variables.$spacing-s;
  min-height: 60px;
  background-color: #f09292;

  > i {
    margin-right: variables.$spacing-xs;
    font-size: x-large;
  }
}
